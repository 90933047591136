import React from 'react';

import Player from '@/features/player';
import { throttle } from '@/lib/google-closure/functions';
import AudioService from '@/services/audio-service';
import ConfigurationService from '@/services/configuration-service';
import LogsService from '@/services/logs-service';
import PostCastService, {
    Channel,
    InitData,
    Post,
    Settings
} from '@/services/post-cast-service';
import StorageService from '@/services/storage-service';
import { PageContent } from '@/shared/ui/layout/page-content';

import FeedPage from './pages/feed-page';
import PlaylistPage from './pages/playlist-page';

type SpaRouterProps = {
    initData: InitData;
    postCastService: PostCastService;
    logsService: LogsService;
    configurationService: ConfigurationService;
    storageService: StorageService;
};

type SpaRouterState = {
    voices: string[];
    searchValue: string;
    displayedPlaylist: string;
    feedLoadedCompletely: boolean;
    feedLoading: boolean;
    playlistLoading: string;
    playing: boolean;
    currentTime: number;
    currentPostId: number;
    playerPlaylist: string;
    scrollTo: string;
    playbackRate: number;
    showLargePlayer: boolean;
    channelPagination: number;
}

class PlayerApp extends React.Component<SpaRouterProps, SpaRouterState> {
    private audioService: AudioService;
    private channelsMap: Record<string, Channel>;
    private postMap: Record<string, Post[]>;
    private channels: Channel[];
    private recents: Channel[];
    private searchedChannels: Channel[];
    private listenedPosts: Record<number, boolean>;

    constructor(props: SpaRouterProps) {
        super(props);

        const { initData } = props;

        const {
            playlistToPlay,
            currentPost,
            selectedVoice,
            time,
            playbackRate,
            posts,
            showChannelPage,
            // playImmediately,
        } = initData.channelToOpen;

        this.channelsMap = initData.channelsMap;
        this.channels = initData.channels;
        this.postMap = initData.postsMap;

        this.recents = [];
        this.updateRecents();
        this.searchedChannels = [];
        this.listenedPosts = {};

        const { playlist, voices } = this.getPlaylistWithVoices(posts, selectedVoice);

        const audioService = new AudioService(
            this.getPostIndexById(playlistToPlay, currentPost),
            playlist,
            time,
            playbackRate,
        );

        this.audioService = audioService;

        this.state = {
            voices,
            searchValue: '',
            displayedPlaylist: showChannelPage ? playlistToPlay : '',
            feedLoadedCompletely: false,
            feedLoading: false,
            playlistLoading: '',
            playing: false,
            currentTime: time,
            currentPostId: currentPost,
            playerPlaylist: playlistToPlay,
            scrollTo: '',
            playbackRate,
            showLargePlayer: initData.channelToOpen.playImmediately,
            channelPagination: 1,
        };

        audioService.onChange(this.handleAudioChange);
        audioService.onProgress(this.handleProgress);
        audioService.onPlay(this.handlePlay);
        audioService.onPause(this.handlePause);
        audioService.onTimeUpdate(this.handleTimeUpdate);
        audioService.onPlaybackRateChange(this.handlePlaybackRateChange);
        Telegram.WebApp.BackButton.onClick(this.handleBack);

        // if (initData.channelToOpen.playImmediately) {
        //     audioService.play();
        // }
    }

    private handleBack = () => {
        this.setState({
            scrollTo: this.state.displayedPlaylist,
            displayedPlaylist: '',
        });

        Telegram.WebApp.BackButton.hide();
    };

    private handleAudioChange = (index: number) => {
        const { playerPlaylist } = this.state;

        const currentPostId = this.getPostIdByIndex(playerPlaylist, index);
        this.setState({
            currentPostId,
        });

        const { settings } = this.props.initData;

        if (this.postMap[playerPlaylist].length - settings.playlistPreloadOffset - index <= 0) {
            this.loadPlaylist(playerPlaylist);
        }
    };

    private handleProgress = (percentage: number) => {
        if (percentage >= 98 && !this.listenedPosts[this.state.currentPostId]) {
            this.listenedPosts[this.state.currentPostId] = true;

            this.props.postCastService.event({
                eventName: 'user_postcast_listened',
                payload: {
                    post_id: this.state.currentPostId,
                }
            });
        }
    };

    private handlePlay = () => {
        this.props.postCastService.event({
            eventName: 'user_postcast_started',
            payload: {
                post_id: this.state.currentPostId,
            }
        });

        this.setState({
            playing: true,
        });
    };

    private handlePause = () => {
        this.setState({
            playing: false,
        });
    };

    private handleTimeUpdate = (currentTime: number) => {
        this.setState({
            currentTime,
        });

        if (currentTime !== 0 && currentTime !== this.state.currentTime) {
            this.updateSettings({
                lastPlaylist: this.state.playerPlaylist,
                lastPostId: this.state.currentPostId,
                time: currentTime,
                playbackRate: this.state.playbackRate,
            });
        }
    };

    private handlePlaybackRateChange = (playbackRate: number) => {
        this.setState({
            playbackRate,
        });
    };

    private updateSettings = throttle(async (settings: Settings) => {
        const { limitExceed } = await this.props.postCastService.updateSettings(settings);

        if (limitExceed) {
            window.location.reload();
        }
    }, 5 * 1000);

    private getPostIndexById = (playlist: string, id?: number) => {
        if (!id) {
            return 0;
        }

        for (let i = 0; i < this.postMap[playlist].length; i++) {
            if (this.postMap[playlist][i].id === id) {
                return i;
            }
        }

        return 0;
    };

    private getPostIdByIndex = (playlist: string, index: number) => {
        return this.postMap[playlist][index].id;
    };

    private getPlaylistWithVoices = (posts: Post[], voice: string) => {
        return posts.reduce<{ playlist: string[]; voices: string[] }>((acc, post) => {
            if (post.audio[voice]) {
                const file = this.props.configurationService.audio(post.audio[voice].filename);

                acc.playlist.push(file);
                acc.voices.push(voice);

                return acc;
            }

            // if (post.audio.novoice) {
            //
            // }

            const firstVoice = Object.keys(post.audio)[0];

            if (firstVoice) {
                const file = this.props.configurationService.audio(post.audio[firstVoice].filename);

                acc.playlist.push(file);
                acc.voices.push(firstVoice);

                return acc;
            }

            const file = this.props.configurationService.audio(post.audio['novoice'].filename);

            acc.playlist.push(file);
            acc.voices.push('novoice');

            return acc;
        }, {
            playlist: [],
            voices: [],
        });
    };

    private getSelectedVoice = () => {
        const currentPostIndex = this.getPostIndexById(this.state.playerPlaylist, this.state.currentPostId);

        return  this.state.voices[currentPostIndex];
    };

    private setPlaylist = (posts: Post[], voice?: string) => {
        if (!voice) {
            voice = this.getSelectedVoice();
        }

        const { playlist, voices } = this.getPlaylistWithVoices(posts, voice);

        this.setState({
            voices,
        });

        this.audioService.setPlaylist(playlist);
    };

    private loadPlaylist = async (username: string) => {
        if (this.state.playlistLoading) {
            return;
        }

        // const loadedAmount = channel.playlist.length;
        // if (loadedAmount >= channel.total) {
        //     return;
        // }

        this.setState({
            playlistLoading: username,
        });


        const posts = this.postMap[username];
        const pagination = posts.length / this.props.initData.settings.paginationPlaylistPageSize;
        const morePosts = await this.props.postCastService.morePosts(username, pagination);

        for (let i = 0; i < morePosts.length; i++) {
            this.postMap[username].push(morePosts[i]);
        }

        this.setPlaylist(this.postMap[username]);

        this.setState({
            playlistLoading: '',
        });
    };

    private updateRecents = () => {
        const list = this.props.storageService.getRecents().reverse();
        this.recents = [];

        for (let i = 0; i < list.length; i++) {
            if (this.channelsMap[list[i]]) {
                this.recents.push(this.channelsMap[list[i]]);
            } else {
                console.log('skip', list[i]); // todo
            }
        }

        this.forceUpdate();
    };

    private loadChannels = async () => {
        if (this.state.feedLoadedCompletely || this.state.feedLoading) {
            return;
        }

        const moreChannelsFromFeed = await this.props.postCastService.moreChannels(
            this.state.channelPagination + 1
        );

        this.setState({
            feedLoading: true,
            channelPagination: this.state.channelPagination + 1,
        });

        /** Handle required channels to avoid dublicates */ // todo rm
        for (let i = 0; i < moreChannelsFromFeed.channels.length; i++) {
            const channel = moreChannelsFromFeed.channels[i];
            const alreadyInFeed = this.channelsMap[channel.username];
            if (!alreadyInFeed) {
                this.channels.push(channel);
                this.channelsMap[channel.username] = channel;
            }
        }

        this.setState({
            feedLoading: false,
        });

        if (moreChannelsFromFeed.isOver) {
            this.props.postCastService.event({
                eventName: 'feed_is_over',
            });
            this.setState({
                feedLoadedCompletely: true,
            });
        }
    };

    private handleSearch = async (value: string) => {
        this.setState({
            searchValue: value,
            feedLoading: true,
        });

        const channels = await this.props.postCastService.search(value);
        this.searchedChannels = channels;
        this.setState({
            feedLoading: false,
        });
    };

    private handleChannelClick = async (username: string) => {
        this.props.storageService.addRecent(username);
        this.updateRecents();

        this.setState({
            searchValue: '',
            displayedPlaylist: username,
        });

        if (!this.postMap[username]) {
            this.setState({
                playlistLoading: username,
            });

            const morePosts = await this.props.postCastService.morePosts(username, 0);
            const posts = [];

            for (let i = 0; i < morePosts.length; i++) {
                posts.push(morePosts[i]);
            }

            this.setPlaylist(posts);

            this.postMap[username] = posts;
            this.setState({
                playlistLoading: '',
            });
        }
    };

    private onPostClick = (playlistName: string, postId: number) => {
        if (this.state.playerPlaylist === playlistName && postId === this.state.currentPostId) {
            this.audioService.togglePlay();

            return;
        }

        this.setState({
            currentPostId: postId,
        });

        if (this.state.playerPlaylist !== playlistName) {
            this.setState({
                playerPlaylist: playlistName,
                currentPostId: postId,
            });

            this.audioService.pause();
            this.setPlaylist(this.postMap[playlistName]);
        }

        this.audioService.playIndex(this.getPostIndexById(playlistName, postId));
    };

    private handleVoiceChange = (voice: string) => {
        this.props.postCastService.event({
            eventName: 'user_voice_change_click',
            payload: {
                post_id: this.state.currentPostId,
            },
        });


        this.props.postCastService.updateSettings({
            selectedVoice: voice,
        });

        this.audioService.pause();
        this.setPlaylist(this.postMap[this.state.playerPlaylist], voice);
        this.audioService.playIndex(this.audioService.current, this.state.currentTime);
    };

    private handleShowLargePlayer = (showLargePlayer: boolean) => {
        this.setState({
            showLargePlayer,
        });
    };

    private handleAuthorClick = (link: string) => {
        this.props.postCastService.event({
            eventName: 'user_goto_telegram_channel',
            payload: {
                post_id: this.state.currentPostId,
            },
        });

        Telegram.WebApp.openTelegramLink(link);
    };

    private handleContentClick = (link: string) => {
        this.props.postCastService.event({
            eventName: 'user_goto_telegram_post',
            payload: {
                post_id: this.state.currentPostId,
            },
        });

        Telegram.WebApp.openTelegramLink(link);
    };

    private handleShareClick = () => {
        const { telegramBotPublicName, telegramBotAppName } = this.props.initData.settings;
        const { currentPostId, playerPlaylist } = this.state;

        this.props.postCastService.event({
            eventName: 'user_share_link_click',
            payload: {
                post_id: currentPostId,
                time: this.state.currentTime,
            },
        });

        // todo here bug with undefined postid
        if (!currentPostId) {
            Telegram.WebApp.openTelegramLink(
                // eslint-disable-next-line max-len
                `https://t.me/share/url?url=https://t.me/${telegramBotPublicName}/${telegramBotAppName}?startapp=${playerPlaylist}`
            );

            return;
        }

        // todo here bug with undefined postid
        Telegram.WebApp.openTelegramLink(
            // eslint-disable-next-line max-len
            `https://t.me/share/url?url=https://t.me/${telegramBotPublicName}/${telegramBotAppName}?startapp=${playerPlaylist}-${currentPostId}`
        );
    };

    public render() {
        const currentPostIndex = this.getPostIndexById(this.state.playerPlaylist, this.state.currentPostId);
        const postToPlay = this.postMap[this.state.playerPlaylist][currentPostIndex];

        return (
            <>
                <PageContent>
                    {
                        this.state.displayedPlaylist ? (
                            <PlaylistPage
                                displayedPlaylist={this.state.displayedPlaylist}
                                loading={this.state.displayedPlaylist === this.state.playlistLoading}
                                posts={this.postMap[this.state.displayedPlaylist] || []}
                                currentPost={currentPostIndex}
                                currentTime={this.state.currentTime}
                                playing={this.state.playing}
                                playerPlaylist={this.state.playerPlaylist}
                                onPostClick={this.onPostClick}
                                onBack={this.props.configurationService.isProd() ? undefined : this.handleBack}
                                onPlaylistLoad={this.loadPlaylist}
                            />
                        ) : (
                            <FeedPage
                                channels={this.channels}
                                recentChannels={this.recents}
                                searchedChannels={this.searchedChannels}
                                searchValue={this.state.searchValue}
                                playingPlaylist={this.state.playerPlaylist}
                                scrollTo={this.state.scrollTo}
                                feedLoading={this.state.feedLoading}
                                configurationService={this.props.configurationService}
                                postCastService={this.props.postCastService}
                                onSearch={this.handleSearch}
                                onLoadMore={this.loadChannels}
                                onChannelClick={this.handleChannelClick}
                            />
                        )
                    }
                </PageContent>

                <Player
                    audio={this.audioService.audio}
                    title={this.channelsMap[this.state.playerPlaylist].title}
                    description={postToPlay.content}
                    playlist={this.state.playerPlaylist}
                    image={this.props.configurationService.image(postToPlay.image)}
                    playing={this.state.playing}
                    voices={postToPlay.audio ? Object.keys(postToPlay.audio) : []}
                    selectedVoice={this.getSelectedVoice()}
                    playbackRate={this.state.playbackRate}
                    togglePlay={this.audioService.togglePlay}
                    playNext={this.audioService.playNext}
                    playPrevious={this.audioService.playPrevious}
                    rewindForward={this.audioService.rewindForward}
                    rewindBack={this.audioService.rewindBack}
                    onVoiceChange={this.handleVoiceChange}
                    increasePlaybackRate={this.audioService.increasePlaybackRate}
                    onShowLargePlayer={this.handleShowLargePlayer}
                    showLargePlayer={this.state.showLargePlayer}
                    onAuthorClick={() => this.handleAuthorClick(this.channelsMap[this.state.playerPlaylist].link)}
                    onContentClick={() => this.handleContentClick(postToPlay.link)}
                    onShareClick={this.handleShareClick}
                />
            </>
        );
    }
}

export default PlayerApp;
