import { FC } from 'react';

import * as UI from './ui';

type TabsProps = {
    tabs: string[];
}

const Tabs: FC<TabsProps> = ({ tabs }) => {
    return (
        <UI.Wrapper>
            <UI.TabsList>
                {
                    tabs.map((tab, index) => (
                        <UI.TabsItem
                            key={tab}
                            selected={index === 0}
                        >
                            {tab}
                        </UI.TabsItem>
                    ))
                }
            </UI.TabsList>
        </UI.Wrapper>
    );
};

export default Tabs;
