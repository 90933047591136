class NavigatorService {
    static LANGUAGE_PARAM = 'lng';


    public getMiniAppStartupParam = (): [string, number, string] | null => {
        const params = new URLSearchParams(window.Telegram?.WebApp.initData);

        const param = params.get('start_param');
        if (!param) {
            return null;
        }

        const [playlist, postToPlay, utm] = param.split('-');

        if (postToPlay) {
            return [playlist, parseInt(postToPlay, 10 || '0'), utm];
        }

        return [playlist, 0, utm];
    };
}

export default NavigatorService;
