import { Spinner } from '@telegram-apps/telegram-ui';
import { FC, useEffect, useState } from 'react';

import Landing from '@/features/landing';
import Paywall from '@/features/paywall';
import ConfigurationService from '@/services/configuration-service';
import LogsService from '@/services/logs-service';
import NavigatorService from '@/services/navigator-service';
import PostCastService, { InitData } from '@/services/post-cast-service';
import StorageService from '@/services/storage-service';
import { Center } from '@/shared/ui/layout/center';

import PlayerApp from './player-app';

type AppProps = {
    logsService: LogsService;
    configurationService: ConfigurationService;
    storageService: StorageService,
}

const App: FC<AppProps> = ({
    logsService,
    configurationService,
    storageService,
}) => {
    const [loading, setLoading] = useState(true);
    const [initData, setInitData] = useState<InitData | null>(null);
    const [showPaywall, setShowPaywall] = useState(false);

    const [navigatorService] = useState(new NavigatorService());

    const [postCastService] = useState(new PostCastService(
        logsService,
        configurationService,
    ));

    useEffect(() => {
        postCastService.onInit(async (info) => {
            if (info.user.limitExceed) {
                postCastService.event({
                    eventName: 'user_show_paywall'
                });

                setShowPaywall(true);
            }

            setInitData(info);
            setLoading(false);
        });

        // Scenario when user comes from telegram miniapp direct link
        // https://core.telegram.org/bots/webapps#direct-link-mini-apps
        const startupChannelParams = navigatorService.getMiniAppStartupParam();
        if (startupChannelParams !== null) {
            const [playlistFromParams, postToPlayFromParams, utm] = startupChannelParams;
            postCastService.event({
                eventName: 'user_came_by_link',
                payload: {
                    post_id: postToPlayFromParams,
                    utm,
                },
            });
            postCastService.getInitData(playlistFromParams, postToPlayFromParams);
        } else {
            postCastService.getInitData();
        }

        window.Telegram.WebApp.ready();
        window.Telegram.WebApp.expand();
        postCastService.event({
            eventName: 'user_bot_started'
        });
    }, []);

    if (window.Telegram.WebApp.platform === 'unknown' && configurationService.isProd()) {
        window.open('https://auchappbot.t.me');

        return (
            <Landing href='https://auchappbot.t.me' />
        );
    }

    if (!initData || loading) {
        return (
            <Center>
                <Spinner size='l' />
            </Center>
        );
    }

    return (
        <>
            {
                showPaywall ? (
                    <Paywall
                        price={initData.premium.price}
                        strikedPrice={initData.premium.strikedPrice}
                        durationSec={initData.premium.durationSec}
                        postCastService={postCastService}
                    />
                ) : null
            }

            <PlayerApp
                initData={initData}
                postCastService={postCastService}
                logsService={logsService}
                configurationService={configurationService}
                storageService={storageService}
            />
        </>
    );
};

export default App;
