import { Avatar, Modal, Radio } from '@telegram-apps/telegram-ui';
import { FC, useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import PostCastService from '@/services/post-cast-service';
import Cell from '@/shared/components/cell';
import Tgico from '@/shared/icons/tgico';

import * as UI from './ui';

type PaywallProps = {
    price: number;
    strikedPrice: number;
    durationSec: number;
    postCastService: PostCastService
}

const Paywall: FC<PaywallProps> = ({
    price,
    strikedPrice,
    durationSec,
    postCastService
}) => {
    const { t } = useTranslation();
    const [showModal, setShowModal] = useState(true);

    const handlePay = useCallback(async () => {
        Telegram.WebApp.MainButton.disable();

        const { invoiceLink } = await postCastService.getInvoice(
            t('premium_subscription.title'),
            t('premium_subscription.description'),
        );
        Telegram.WebApp.openInvoice(invoiceLink, () => {
            window.location.reload();
        });
    }, []);

    useEffect(() => {
        Telegram.WebApp.MainButton.setText(t('premium_subscription.payButtonCaption'));
        Telegram.WebApp.MainButton.show();
        Telegram.WebApp.MainButton.onClick(handlePay);

        return () => {
            Telegram.WebApp.MainButton.hide();
        };
    }, [handlePay]);

    return (
        <Modal
            open={showModal}
            onOpenChange={() => { setShowModal(false); }}
            preventScrollRestoration
        >
            <UI.Wrapper>
                <UI.Head>
                    <Avatar
                        size={96}
                        src='/static/default.svg'
                    />

                    <UI.Title>{t('premium_subscription.title')}</UI.Title>
                    <UI.Description>{t('premium_subscription.description')}</UI.Description>
                </UI.Head>

                <UI.Plans>
                    <Cell
                        hovered
                        before={
                            <Radio
                                checked
                                name='radio'
                                value='1'
                            />
                        }
                        head={
                            <UI.PlanText>
                                {t('premium_subscription.duration', { count: Math.round(durationSec / 60 / 60 / 24) })}
                            </UI.PlanText>
                        }
                        after={
                            <UI.PriceWrapper>
                                {
                                    strikedPrice ?
                                        <>
                                            <UI.StrikedPrice>
                                                {strikedPrice}
                                            </UI.StrikedPrice>
                                        </>  : null
                                }

                                {price}

                                <Tgico
                                    inline
                                    type='star'
                                    size={1.5}
                                />
                            </UI.PriceWrapper>
                        }
                    />

                </UI.Plans>

                <UI.FeatureList>
                    <UI.FeatureItem>
                        <Tgico
                            inline
                            type='volume'
                            size={1.5}
                        />

                        {t('premium_subscription.features.unlimited_listening')}
                    </UI.FeatureItem>

                    <UI.FeatureItem>
                        <Tgico
                            inline
                            type='gift'
                            size={1.5}
                        />

                        <span>
                            <Trans i18nKey='premium_subscription.features.celebrity_voices'>
                                Voice content with celebrity voices. Create your own folders <UI.InProgress>(in progress)</UI.InProgress>
                            </Trans>
                        </span>
                    </UI.FeatureItem>

                    <UI.FeatureItem>
                        <Tgico
                            inline
                            type='add'
                            size={1.5}
                        />

                        <span>
                            <Trans i18nKey='premium_subscription.features.add_channels'>
                                Add and listen to channels not yet available in Auchapp <UI.InProgress>(in progress)</UI.InProgress>
                            </Trans>
                        </span>
                    </UI.FeatureItem>

                    <UI.FeatureItem>
                        <Tgico
                            inline
                            type='mic'
                            size={1.5}
                        />

                        <span>
                            <Trans i18nKey='premium_subscription.features.create_podcasts'>
                                Create your own podcasts by uploading your text <UI.InProgress>(in progress)</UI.InProgress>
                            </Trans>
                        </span>
                    </UI.FeatureItem>
                </UI.FeatureList>
            </UI.Wrapper>
        </Modal>
    );
};

export default Paywall;
