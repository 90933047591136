import styled from 'styled-components';

import { TextBold16, TextRegular14 } from '@/shared/typography';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
`;

export const Head = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 12px 0;
`;

export const Plans = styled.div`
  width: 100%;
`;

export const Title = styled.h1`
  ${TextBold16};

  margin: 12px 0 10px;
`;

export const Description = styled.span`
  ${TextRegular14};
  
  color: ${props => props.theme.colors.textSecondary};
`;

export const FeatureList = styled.ul`
  list-style-type: none;
  
  padding: 0 12px 42px;
`;

export const FeatureItem = styled.li`
  display: inline-flex;
  align-items: center;
  font-size: 18px;
  margin: 10px 0;
  text-align: left;
  gap: 12px;
`;

export const PriceWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.textPrimary};
`;

export const StrikedPrice = styled.div`
  margin-right: 12px;
  text-decoration: line-through;
  color: red;
`;

export const PlanText = styled.div`
  ${TextBold16};
`;

export const Price = styled.div`
  ${TextBold16}; 
`;

export const InProgress = styled.span`
  color: red; 
`;
