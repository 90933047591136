import { Avatar, Spinner } from '@telegram-apps/telegram-ui';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Search from '@/features/search';
import ConfigurationService from '@/services/configuration-service';
import PostCastService, { Channel } from '@/services/post-cast-service';
import Cell from '@/shared/components/cell';
import Tabs from '@/shared/components/tabs';

import * as UI from './ui';

type FeedPageProps = {
    channels: Channel[];
    recentChannels: Channel[];
    searchedChannels: Channel[];
    playingPlaylist: string;
    scrollTo: string;
    feedLoading: boolean;
    searchValue: string;
    configurationService: ConfigurationService;
    postCastService: PostCastService;
    onLoadMore: VoidFunction;
    onSearch: (value: string) => void;
    onChannelClick: (username: string) => void;
};

const FeedPage: FC<FeedPageProps> = ({
    channels,
    recentChannels,
    searchedChannels,
    playingPlaylist,
    scrollTo,
    feedLoading,
    searchValue,
    configurationService,
    postCastService,
    onLoadMore,
    onSearch,
    onChannelClick,
}) => {
    const { t } = useTranslation();
    const fetchBar = useRef<HTMLDivElement>(null);
    const currentPlayingRef = useRef<HTMLDivElement>(null);

    const [searchFocused, setSearchFocused] = useState(false);

    useEffect(() => {
        if (currentPlayingRef.current) {
            currentPlayingRef.current.scrollIntoView({
                block: 'center',
                inline: 'nearest'
            });
        }
    }, [scrollTo]);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        onLoadMore();
                    }
                });
            },
            {
                root: null,
                threshold: 0.1,
            },
        );

        if (fetchBar.current) {
            observer.observe(fetchBar.current);
        }

        return () => {
            if (fetchBar.current) {
                observer.unobserve(fetchBar.current);
            }
        };
    }, [fetchBar]);


    const handleFocusSearch = useCallback(() => {
        setSearchFocused(true);

        postCastService.event({
            eventName: 'user_search_field_click',
        });
    }, []);

    const handleCancel = useCallback(() => {
        onSearch('');
        setSearchFocused(false);

        postCastService.event({
            eventName: 'user_search_cancel_click',
        });
    }, []);


    let subtitle = '';
    let results = channels;

    if (searchFocused) {
        subtitle = t('search.recents').toUpperCase();
        results = recentChannels;
        if (searchValue) {
            subtitle = t('search.results').toUpperCase();
            results = searchedChannels;
        }
    }

    return (
        <UI.Wrapper $withSubtitile={Boolean(subtitle)}>
            <UI.Head>
                <Search
                    value={searchValue}
                    onFocus={handleFocusSearch}
                    onChange={onSearch}
                    onCancel={searchFocused ? handleCancel : undefined}
                />

                <Tabs
                    tabs={
                        [
                            t('tabs.all'),
                            t('tabs.tech'),
                            t('tabs.marketing'),
                            t('tabs.education'),
                            t('tabs.politics'),
                            t('tabs.psy')
                        ]
                    }
                />

                {
                    subtitle && <UI.Subtitle>{subtitle}</UI.Subtitle>
                }
            </UI.Head>

            <UI.List>
                {
                    results.map((channel: Channel) => (
                        <React.Fragment key={channel.username}>
                            <div ref={channel.username === scrollTo ? currentPlayingRef : undefined}>
                                <Cell
                                    key={channel.title}
                                    before={
                                        <UI.AvatarWrapper>
                                            <Avatar
                                                size={48}
                                                src={configurationService.image(channel.photo)}
                                            />

                                            {
                                                channel.username === playingPlaylist && (
                                                    <UI.PlayinBadge>
                                                        <svg
                                                            width='24'
                                                            height='24'
                                                            viewBox='0 0 24 24'
                                                            fill='none'
                                                            xmlns='http://www.w3.org/2000/svg'
                                                        >
                                                            <circle
                                                                cx='12'
                                                                cy='12'
                                                                r='12'
                                                                fill='white'
                                                            />

                                                            <circle
                                                                cx='12'
                                                                cy='12'
                                                                r='10'
                                                                fill='#007AFE'
                                                            />

                                                            <rect
                                                                x='15'
                                                                y='9'
                                                                width='2'
                                                                height='6'
                                                                rx='1'
                                                                fill='white'
                                                            />

                                                            <rect
                                                                x='7'
                                                                y='10'
                                                                width='2'
                                                                height='4'
                                                                rx='1'
                                                                fill='white'
                                                            />

                                                            <rect
                                                                x='11'
                                                                y='8'
                                                                width='2'
                                                                height='8'
                                                                rx='1'
                                                                fill='white'
                                                            />
                                                        </svg>
                                                    </UI.PlayinBadge>
                                                )
                                            }
                                        </UI.AvatarWrapper>
                                    }
                                    head={channel.title}
                                    body={channel.description}
                                    after={
                                        channel.score != -1 ?
                                            <svg
                                                width='20'
                                                height='20'
                                                viewBox='0 0 20 20'
                                                fill='none'
                                                xmlns='http://www.w3.org/2000/svg'
                                            >
                                                <path
                                                    d='M8.3331 13.7226L4.86993 16.2114C4.83458 16.2368 4.80195 16.2658 4.76861 16.2938C4.7097 16.3433 4.64346 16.3835 4.57215 16.4131C4.48066 16.451 4.38259 16.4705 4.28356 16.4705C4.18452 16.4705 4.08646 16.451 3.99496 16.4131C3.90346 16.3752 3.82033 16.3196 3.7503 16.2496C3.68027 16.1796 3.62472 16.0964 3.58682 16.0049C3.54892 15.9134 3.52942 15.8153 3.52942 15.7163C3.52942 15.6173 3.54892 15.5192 3.58682 15.4277C3.62472 15.3362 3.68027 15.2531 3.7503 15.183L6.42416 11.8973C6.68706 11.5742 6.6599 11.1039 6.36156 10.8132L4.59639 9.09351C4.2753 8.78069 4.27101 8.26125 4.64211 8.00978C5.38084 7.50918 6.57401 6.99996 8.30584 7.04524C8.53835 7.05132 8.76675 6.96834 8.93123 6.80389L11.4739 4.26183L11.9708 3.76494C12.285 3.45075 12.7944 3.45075 13.1085 3.76494L13.6054 4.26183L15.7377 6.39341L16.235 6.89071C16.5491 7.20489 16.5491 7.71427 16.235 8.02845L15.7377 8.52574L13.2193 11.0442C13.0414 11.2221 12.9598 11.474 12.9816 11.7248C13.1416 13.5656 12.5675 14.6877 12.0235 15.3813C11.7459 15.7353 11.2178 15.7215 10.9056 15.3977L9.38171 13.8174C9.10426 13.5297 8.65767 13.4893 8.3331 13.7226Z'
                                                    fill='#B7B7B7'
                                                />
                                            </svg> : undefined
                                    }
                                    onClick={() => onChannelClick(channel.username)}
                                />

                                <UI.Underline />
                            </div>
                        </React.Fragment>
                    ))
                }

                <UI.FetchBar ref={fetchBar} />
            </UI.List>

            {
                feedLoading && (
                    <UI.SpinnerWrapper>
                        <Spinner size='m' />
                    </UI.SpinnerWrapper>
                )
            }

        </UI.Wrapper>
    );
};

export default FeedPage;
